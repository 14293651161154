import React, { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@btc/shared";

import { Meta } from "./common/meta";
import { Content } from "./imprint/content";

const Template = ({ data }) => {
  const content = {
    title: getProperty(data, "markdownRemark.frontmatter.title"),
    content: getProperty(data, "markdownRemark.html"),
  };
  const meta = getProperty(data, "markdownRemark.frontmatter.meta");

  return (
    <Fragment>
      <Content data={content} />
      <Meta data={meta} />
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title
        meta {
          title
          description
        }
      }
      html
    }
  }
`;
